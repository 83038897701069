<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <div v-if="hasHtml() && !canEdit" v-html="form.fields.historicoDaDoencaAtual" class="form-group mb-0 break-spaces"></div>
    <div v-else class="form-group mb-0 break-spaces">
      <TextArea
        id="historia-da-doenca-atual"
        :value="form.fields.historicoDaDoencaAtual"
        @blur="updateMedicalRecord(form)"
        @inputTextArea="el => updateForm('historicoDaDoencaAtual', el)"
        :readonly="!canEdit"
        rows="1"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    TextArea: () => import('@/components/General/TextArea'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.historiaDaDoencaAtual,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/historiaDaDoencaAtual', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hasHtml() {
      return /<\/?[a-z][\s\S]*>/i.test(this.form.fields.historicoDaDoencaAtual);
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const attendanceData = data.find(el => { return el.type === 'historia-da-doenca-atual'})

      if (!attendanceData?.id) return
      Object.keys(attendanceData.value).map(key => {
        if (attendanceData.value[key]) 
          this.updateForm(`${key}`, attendanceData.value[key])
      })
      await this.updateMedicalRecord(this.form)
    },
  }
}
</script>
